export default [
  {
    path: '/pendamping-umkm',
    name: 'pendamping-umkm',
    component: () => import('@/views/pages/pumkm/Index.vue'),
    meta: {
      resource: 'PUMKM',
      navActiveLink: 'pendamping-umkm',
      action: 'manage',
      routeEdit: 'edit-data-pumkm',
    },
  },
  {
    path: '/add-pumkm',
    name: 'add-pumkm',
    component: () => import('@/views/pages/pumkm/add-pumkm/AddPumkm.vue'),
    meta: {
      resource: 'PUMKM',
      navActiveLink: 'pendamping-umkm',
      action: 'manage',
    },
  },
  {
    path: '/edit-data-pumkm/:pumkm_id/:affiliator_id',
    name: 'edit-data-pumkm',
    component: () => import('@/views/pages/pumkm/edit-data-pumkm/EditDataPumkm.vue'),
    meta: {
      resource: 'PUMKM',
      navActiveLink: 'pendamping-umkm',
      action: 'manage',
    },
  },
]
