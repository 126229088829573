import { axiosKomshipNew } from '@/store/helpers'

const secretKey = process.env.VUE_APP_BASE_SECRET_KEY_KOMFORM

export default {
  namespaced: true,
  state: () => ({
    userRegisterCount: 0,
    checkoutLinksCount: 0,
    ordersCount: 0,
    registerChart: null,
    checkoutLinksChartItem: null,
    orderChartItem: null,
    elementsStandardChartItem: null,
    elementsCustomChartItem: null,
    ordersCountStatusItem: null,

    seriesStatusChart: [],

    elementCountStatusItem: {
      all: 0,
      active: 0,
      inactive: 0,
    },

    userDetail: {
      listItem: [],
      perPage: 5,
      currentPage: 1,
      period: 'MINGGU_INI',
      totalRows: 0,
    },
  }),
  mutations: {
    UPDATE_REGISTER_COUNT(state, args) {
      state.userRegisterCount = args
    },
    UPDATE_CHECKOUT_LINKS_COUNT(state, args) {
      state.checkoutLinksCount = args
    },
    UPDATE_ORDERS_COUNT(state, args) {
      state.ordersCount = args
    },
    UPDATE_CHART_USER_REGISTER(state, args) {
      state.registerChart = args
    },
    UPDATE_CHART_CHECKOUT_LINKS(state, args) {
      state.checkoutLinksChartItem = args
    },
    UPDATE_CHART_ORDER(state, args) {
      state.orderChartItem = args
    },
    UPDATE_ELEMENTS_STANDARD_CHART(state, args) {
      state.elementsStandardChartItem = args
    },
    UPDATE_ELEMENTS_CUSTOM_CHART(state, args) {
      state.elementsCustomChartItem = args
    },
    UPDATE_ORDERS_COUNT_STATUS(state, args) {
      state.ordersCountStatusItem = args
    },
    UPDATE_CHECKOUT_LINKS_STATUS(state, args) {
      const findActive = args.find(item => item.status === 'ACTIVE')
      const findInActive = args.find(item => item.status === 'INACTIVE')
      const all = findActive.count + findInActive.count
      state.elementCountStatusItem.active = findActive.count
      state.elementCountStatusItem.inactive = findInActive.count
      state.elementCountStatusItem.all = all
    },
    UPDATE_USER_DETAILS(state, args) {
      state.userDetail.listItem = args.items
      state.userDetail.perPage = args.per_page
      state.userDetail.currentPage = args.current_page
      state.userDetail.totalRows = args.total
    },
    UPDATE_SERIES_STATUS_CHART(state, args) {
      const findClosing = args.find(item => item.label === 'CLOSING')
      const findNotClosing = args.find(item => item.label === 'TIDAK_CLOSING')
      const findOpen = args.find(item => item.label === 'OPEN')
      const findRatioClosing = args.find(item => item.label === 'RATIO_CLOSING')
      state.seriesStatusChart = [
        {
          name: 'Closing',
          data: findClosing ? findClosing.data.map(item => item.value) : [],
        },
        {
          name: 'Tidak Closing',
          data: findNotClosing ? findNotClosing.data.map(item => item.value) : [],
        },
        {
          name: 'Order',
          data: findOpen ? findOpen.data.map(item => item.value) : [],
        },
        {
          name: 'Ratio Closing',
          data: findRatioClosing ? findRatioClosing.data.map(item => item.value) : [],
        },
      ]
    },
  },
  actions: {
    registerCount({ commit }) {
      return new Promise((resolve, reject) => {
        axiosKomshipNew()
          .get('auth/api/v1/admin/komform/user-register-count')
          .then(response => {
            commit('UPDATE_REGISTER_COUNT', response.data.data)
            resolve(response)
          })
          .catch(err => {
            reject(err)
          })
      })
    },

    async checkoutLinksCount({ commit }) {
      return new Promise((resolve, reject) => {
        fetch(`${process.env.VUE_APP_BASE_URL_KOMFORM}/v1/admin/checkout-links/count?secret=${secretKey}`).then(response => {
          response.json().then(res => {
            commit('UPDATE_CHECKOUT_LINKS_COUNT', res.data)
            resolve(res)
          })
        }).catch(err => {
          reject(err)
        })
      })
    },

    async orderCount({ commit }) {
      return new Promise((resolve, reject) => {
        fetch(`${process.env.VUE_APP_BASE_URL_KOMFORM}/v1/admin/orders/count?secret=${secretKey}`).then(response => {
          response.json().then(res => {
            commit('UPDATE_ORDERS_COUNT', res.data)
            resolve(res)
          })
        }).catch(err => {
          reject(err)
        })
      })
    },

    async checkoutLinksChart({ commit }, payload) {
      let url = ''
      switch (payload) {
        case ('BULAN_INI'):
          url = '/v1/admin/checkout-links/trends/bulan-ini'
          break
        case ('MINGGU_INI'):
          url = '/v1/admin/checkout-links/trends/minggu-ini'
          break
        case ('SEMUA_WAKTU'):
          url = '/v1/admin/checkout-links/trends'
          break
        default:
          break
      }
      return new Promise((resolve, reject) => {
        fetch(`${process.env.VUE_APP_BASE_URL_KOMFORM}${url}?secret=${secretKey}`).then(response => {
          response.json().then(res => {
            commit('UPDATE_CHART_CHECKOUT_LINKS', res.data)
            resolve(res)
          })
        }).catch(err => {
          reject(err)
        })
      })
    },

    async orderChart({ commit }, payload) {
      let url = ''
      switch (payload) {
        case ('BULAN_INI'):
          url = '/v1/admin/orders/trends/bulan-ini'
          break
        case ('MINGGU_INI'):
          url = '/v1/admin/orders/trends/minggu-ini'
          break
        case ('SEMUA_WAKTU'):
          url = '/v1/admin/orders/trends'
          break
        default:
          break
      }
      return new Promise((resolve, reject) => {
        fetch(`${process.env.VUE_APP_BASE_URL_KOMFORM}${url}?secret=${secretKey}`).then(response => {
          response.json().then(res => {
            commit('UPDATE_CHART_ORDER', res.data)
            resolve(res)
          })
        }).catch(err => {
          reject(err)
        })
      })
    },

    elementsChart({ commit }, payload) {
      return new Promise((resolve, reject) => {
        fetch(`${process.env.VUE_APP_BASE_URL_KOMFORM}/v1/admin/elements/count?category=${payload.category}&filter=${payload.filter}&secret=${secretKey}`).then(response => {
          response.json().then(res => {
            if (payload.category === 'STANDARD') commit('UPDATE_ELEMENTS_STANDARD_CHART', res.data)
            if (payload.category === 'CUSTOM') commit('UPDATE_ELEMENTS_CUSTOM_CHART', res.data)
            resolve(res)
          })
        }).catch(err => {
          reject(err)
        })
      })
    },

    elementsCountStatus({ commit }) {
      return new Promise((resolve, reject) => {
        fetch(`${process.env.VUE_APP_BASE_URL_KOMFORM}/v1/admin/checkout-links/count/status?secret=${secretKey}`).then(response => {
          response.json().then(res => {
            commit('UPDATE_CHECKOUT_LINKS_STATUS', res.data)
            resolve(res)
          })
        }).catch(err => {
          reject(err)
        })
      })
    },

    orderCountStatus({ commit }, payload) {
      return new Promise((resolve, reject) => {
        fetch(`${process.env.VUE_APP_BASE_URL_KOMFORM}/v1/admin/orders/count/status?secret=${secretKey}&period=${payload}`).then(response => {
          response.json().then(res => {
            commit('UPDATE_ORDERS_COUNT_STATUS', res.data)
            resolve(res)
          })
        }).catch(err => {
          reject(err)
        })
      })
    },

    orderCountStatusChart({ commit }, payload) {
      let url
      if (payload === 'MINGGU_INI') {
        url = `/v1/admin/orders/trends/status/minggu-ini?secret=${secretKey}`
      } else if (payload === 'BULAN_INI') {
        url = `/v1/admin/orders/trends/status/bulan-ini?secret=${secretKey}`
      } else {
        url = `/v1/admin/orders/trends/status?secret=${secretKey}`
      }
      return new Promise((resolve, reject) => {
        fetch(`${process.env.VUE_APP_BASE_URL_KOMFORM}${url}`).then(response => {
          response.json().then(res => {
            commit('UPDATE_SERIES_STATUS_CHART', res.data)
            resolve(res)
          })
        }).catch(err => {
          reject(err)
        })
      })
    },

    registerChart({ commit }, payload) {
      return new Promise((resolve, reject) => {
        axiosKomshipNew()
          .get(`auth/api/v1/admin/komform/user-register-chart?period=${payload}`)
          .then(response => {
            commit('UPDATE_CHART_USER_REGISTER', response.data.data)
            resolve(response)
          })
          .catch(err => {
            reject(err)
          })
      })
    },

    listUserDetails({ commit }, payload) {
      return new Promise((resolve, reject) => {
        axiosKomshipNew()
          .get(`auth/api/v1/admin/komform/user-details?period=${payload.period}&page=${payload.page}&limit=${payload.limit}&username=${payload.username}`)
          .then(response => {
            commit('UPDATE_USER_DETAILS', response.data.data)
            resolve(response)
          })
          .catch(err => {
            reject(err)
          })
      })
    },
  },
}
