export default [
  {
    path: '/partner',
    name: 'partner',
    component: () => import('@/views/pages/partner/list-partner/List.vue'),
    meta: {
      name: 'Partner',
      routeCreate: 'partner-create',
      routeEdit: 'partner-edit',
      resource: 'Komtim - Partner',
      action: 'manage',
    },
  },
  {
    path: '/partner/create',
    name: 'partner-create',
    component: () => import('@/views/pages/partner/list-partner/Add.vue'),
    meta: {
      name: 'Partner',
      resource: 'Komtim - Partner',
      action: 'manage',
    },
  },
  {
    path: '/partner/:id/edit',
    name: 'partner-edit',
    component: () => import('@/views/pages/partner/list-partner/Edit.vue'),
    meta: {
      name: 'Partner',
      resource: 'Komtim - Partner',
      action: 'manage',
    },
  },
]
