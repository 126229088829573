import { axiosKomshipNew } from '@/store/helpers'

export default {
  namespaced: true,
  state: () => ({
    listOrder: null,
  }),
  mutations: {
    LIST_ORDER_UPDATE(state, args) {
      state.listOrder = args
    },
  },
  actions: {
    list({ commit }, payload) {
      const {
        startDate,
        endDate,
        offset,
        limit,
        shipping,
        q,
      } = payload
      let url = null
      if (q === null || q === '') {
        url = `/komship/api/v1/admin/order/force-delivered?start_date=${startDate}&end_date=${endDate}&offset=${offset}&limit=${limit}&shipping=${shipping}`
      } else {
        url = `/komship/api/v1/admin/order/force-delivered?start_date=${startDate}&end_date=${endDate}&offset=${offset}&limit=${limit}&shipping=${shipping}&q=${q}`
      }
      return new Promise((resolve, reject) => {
        axiosKomshipNew()
          .get(
            url,
          )
          .then(response => {
            commit('LIST_ORDER_UPDATE', response.data.data)
            resolve(response)
          })
          .catch(err => {
            reject(err.response)
          })
      })
    },
    proofed({ commit }, payload) {
      return new Promise((resolve, reject) => {
        axiosKomshipNew()
          .get(
            `/komship/api/v1/admin/order/${payload}/force-delivered-proof`,
          )
          .then(response => {
            resolve(response)
          })
          .catch(err => {
            reject(err.response)
          })
      })
    },
  },
}
