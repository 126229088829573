export default [
  {
    path: '/komcards-dashboard',
    name: 'komcards-dashboard',
    component: () => import('@/views/pages/komcards/dashboard/Index.vue'),
    meta: {
      resource: 'Komcards',
      navActiveLink: 'komcards-dashboard',
      action: 'read',
    },
  },
  {
    path: '/verification-komcards',
    name: 'verification-komcards',
    component: () => import('@/views/pages/komcards/verification/List.vue'),
    meta: {
      resource: 'Komcards',
      navActiveLink: 'verification-komcards',
      action: 'read',
    },
  },
  {
    path: '/verification-komcards/history/:id',
    name: 'verification-komcards',
    component: () => import('@/views/pages/komcards/verification/ListHistory.vue'),
    meta: {
      resource: 'Komcards',
      navActiveLink: 'verification-komcards',
      action: 'read',
    },
  },
  {
    path: '/verification-komcards/:id/file/:id_partner',
    name: 'verification-komcards',
    component: () => import('@/views/pages/komcards/verification/File.vue'),
    meta: {
      resource: 'Komcards',
      navActiveLink: 'verification-komcards',
      action: 'read',
    },
  },
  {
    path: '/verification-komcards/history/:id/:id_history/:id_partner',
    name: 'verification-komcards',
    component: () => import('@/views/pages/komcards/verification/FileDetail.vue'),
    meta: {
      resource: 'Komcards',
      navActiveLink: 'verification-komcards',
      action: 'read',
    },
  },
]
