export default [
  {
    path: '/notification',
    name: 'notification',
    component: () => import('@/views/pages/notification/List.vue'),
    meta: {
      name: 'notification',
      routeDetail: 'notification-detail',
      resource: 'Campaign',
      action: 'manage',
    },
  },
  {
    path: '/notification/create',
    name: 'notification-create',
    component: () => import('@/views/pages/notification/AddNotif.vue'),
    meta: {
      name: 'notification-create',
      resource: 'Campaign',
      action: 'manage',
    },
  },
  {
    path: '/notification/create-blast',
    name: 'notification-create-blast',
    component: () => import('@/views/pages/notification/AddBlast.vue'),
    meta: {
      name: 'notification-create-blast',
      resource: 'Campaign',
      action: 'manage',
    },
  },
  {
    path: '/notification/detail/notif/:id',
    name: 'notification-detail',
    component: () => import('@/views/pages/notification/DetailNotif.vue'),
    meta: {
      name: 'notification-detail',
      resource: 'Campaign',
      action: 'manage',
    },
  },
  {
    path: '/notification/detail/blast/:id',
    name: 'blast-detail',
    component: () => import('@/views/pages/notification/DetailBlast.vue'),
    meta: {
      name: 'blast-detail',
      resource: 'Campaign',
      action: 'manage',
    },
  },
  {
    path: '/notification/create-blast/:id',
    name: 'create-blast-id',
    component: () => import('@/views/pages/notification/AddBlast.vue'),
    meta: {
      name: 'blast-detail',
      resource: 'Campaign',
      action: 'manage',
    },
  },
]
